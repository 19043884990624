import httpClient from "@/services/http.service"
import router from "@/router"


const ppsOfficeHourService = {
  get() {
    return httpClient.get(`teaching_load/pps-office-hour/get-office-hour`)
  },
  create(data) {
    return httpClient.post(`teaching_load/pps-office-hour/create`, data)
  },
  update(data) {
    return httpClient.put(`teaching_load/pps-office-hour/update`, data)
  }
}

export default ppsOfficeHourService