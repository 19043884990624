<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Офисные часы</h4>

      <!-- list of teachers -->
      <div class="mt-4" v-if="teachers.length">
        <DataTable :value="teachers" :paginator="true" :rows="10" showGridlines
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                   responsiveLayout="scroll">
          <Column header="ФИО">
            <template #body="{data}">
              {{data.lastname}} {{data.firstname}} {{data.middlename}}
            </template>
          </Column>
          <Column header="Офисные часы" style="width: 150px">
            <template #body="{data}">
              <input type="number" class="form-control" placeholder="0" v-model="data.hour">
            </template>
          </Column>
        </DataTable>

        <div class="text-center my-4">
          <button type="button" class="btn btn-primary" @click="saveOfficeHour" :disabled="btnSaveOfficeHourStatus">
            <span v-if="btnSaveOfficeHourStatus" class="spinner-grow spinner-grow-sm" role="status"
                  aria-hidden="true"></span>
            {{ btnSaveOfficeHourStatus ? 'Сохранение' : 'Сохранить' }}
          </button>
        </div>

      </div>
      <!-- end list of teachers -->

      <div v-else>
        <h5 class="text-center mt-4">
          Здесь пусто
        </h5>
      </div>


    </div>
  </div>
</template>


<script>
  import ppsOfficeHourService from "@/services/load-calculation/ppsOfficeHour.service"

  export default {
    name: "OfficeHours",
    data() {
      return {
        loading: true,
        teachers: [],
        btnSaveOfficeHourStatus: false
      }
    },
    methods: {
      async getPpsOfficeHour() {
        const {data} = await ppsOfficeHourService.get()

        this.teachers = data
        console.log(this.teachers)
      },
      async saveOfficeHour() {
        this.btnSaveOfficeHourStatus = true
        const putData = this.teachers.filter(t => !!t.id)
        const postData = this.teachers.filter(t => !t.id && t.hour > 0)

        console.log(putData, 'putData')
        console.log(postData, 'postData')
        try {

          const resPut = await ppsOfficeHourService.update(putData)
          const resPost = await ppsOfficeHourService.create(postData)
          if (resPut.status === 200) {
            await this.getPpsOfficeHour()
            this.$message({text: 'Данные успешно обновились'})
          }
          if (resPost.status === 200) {
            await this.getPpsOfficeHour()
            this.$message({text: 'Данные успешно сохранились'})
          }
        } catch (e) {
          this.$error({title: `Ошибка ${e.message}`, text: JSON.stringify(e?.response?.data?.errors)})
        }

        this.btnSaveOfficeHourStatus = false
      }
    },
    async mounted() {
      await this.getPpsOfficeHour()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>